import React from "react";
import { Box, styled } from "@mui/material";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useIntl } from "react-intl";
import { useLocalization } from "gatsby-theme-i18n";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { JsonLd } from "gatsby-plugin-next-seo";

import { DefaultLayout, StyledDivider } from "~/views";

const ContentsBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  padding: "15px 20px",
});

const Title = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#f7f7f7",
  fontWeight: "bold",
  padding: "0 10px",
});

const Section = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
});

const getLocalizedMapUrl = (locale) => {
  switch (locale) {
    case "ko":
      return "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3172.4365829842577!2d126.95613771558357!3d37.33217154527238!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357b5d186c156579%3A0x23ce15e1e5d58859!2zKOyjvCnsnoTtjpkg7JeU7YSw7ZSE65287J207KaI!5e0!3m2!1sko!2skr!4v1649177097625!5m2!1sko!2skr";

    default:
      return "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3172.4365829842577!2d126.95613771558357!3d37.33217154527238!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357b5d186c156579%3A0x23ce15e1e5d58859!2sIMPEC%20Enterprise%20Co.%2C%20Ltd.!5e0!3m2!1sen!2skr!4v1649177629472!5m2!1sen!2skr";
  }
};

export default function CompanyPage({ data, location }) {
  const { locale } = useLocalization();
  const { logo } = data;

  const { formatMessage } = useIntl();
  const t = (id) => formatMessage({ id });

  return (
    <DefaultLayout location={location}>
      <GatsbySeo
        title={locale === "ko" ? "회사소개" : "Company"}
        titleTemplate="%s | IMPEC"
        description={t("seo-company-description")}
      />
      <JsonLd
        json={{
          "@context": "http://schema.org",
          "@type": "Person",
          name: "IMPEC Enterprise Official Pages",
          url: "http://www.impec.com",
          sameAs: ["https://blog.naver.com/impec_enterprise"],
        }}
      />

      <ContentsBox>
        <h2>{t("company-vision")}</h2>
        <GatsbyImage
          image={getImage(logo)}
          alt="Meaning of the IMPEC"
          style={{ alignSelf: "center" }}
        />
        <p>
          {t("company-vision-1")}
          <br />
          {t("company-vision-2")}
        </p>
        <p>{t("company-vision-3")}</p>
        <p>{t("company-vision-4")}</p>
        <p style={{ color: "red" }}>{t("company-vision-5")}</p>
      </ContentsBox>
      <StyledDivider style={{ marginTop: "50px" }} />
      <ContentsBox>
        <h2>{t("company-history")}</h2>
        <Box
          style={{
            display: "grid",
            maxWidth: 650,
            alignSelf: "center",
            gridTemplate: "auto / auto 1fr",
            gap: "22px 10px",
          }}
        >
          <Title>1996</Title>
          <Section>
            <Box>{t("company-history-1996-1")}</Box>
            <Box>{t("company-history-1996-2")}</Box>
          </Section>

          <Title>1997 ~ 2000</Title>
          <Section>
            <Box>{t("company-history-1997-1")}</Box>
            <Box>{t("company-history-1997-2")}</Box>
            <Box>{t("company-history-1997-3")}</Box>
            <Box>&nbsp;</Box>
            <Box>{t("company-history-1997-4")}</Box>
            <Box>{t("company-history-1997-5")}</Box>
            <Box>{t("company-history-1997-6")}</Box>
            <Box>&nbsp;</Box>
            <Box>{t("company-history-1997-7")}</Box>
            <Box>{t("company-history-1997-8")}</Box>
            <Box>{t("company-history-1997-9")}</Box>
            <Box>{t("company-history-1997-10")}</Box>
            <Box>&nbsp;</Box>
            <Box>{t("company-history-1997-11")}</Box>
          </Section>

          <Title>2005 ~ 2008</Title>
          <Section>
            <Box>{t("company-history-2005-1")}</Box>
            <Box>{t("company-history-2005-2")}</Box>
            <Box>{t("company-history-2005-3")}</Box>
            <Box>{t("company-history-2005-4")}</Box>
            <Box>&nbsp;</Box>
            <Box>{t("company-history-2005-5")}</Box>
            <Box>{t("company-history-2005-6")}</Box>
            <Box>&nbsp;</Box>
            <Box>{t("company-history-2005-7")}</Box>
            <Box>{t("company-history-2005-8")}</Box>
            <Box>&nbsp;</Box>
            <Box>{t("company-history-2005-9")}</Box>
            <Box>{t("company-history-2005-10")}</Box>
          </Section>

          <Title>2009 ~ 2012</Title>
          <Section>
            <Box>{t("company-history-2009-1")}</Box>
            <Box>{t("company-history-2009-2")}</Box>
            <Box>&nbsp;</Box>
            <Box>{t("company-history-2009-3")}</Box>
            <Box>{t("company-history-2009-4")}</Box>
            <Box>{t("company-history-2009-5")}</Box>
            <Box>&nbsp;</Box>
            <Box>{t("company-history-2009-6")}</Box>
            <Box>{t("company-history-2009-7")}</Box>
            <Box>{t("company-history-2009-8")}</Box>
            <Box>&nbsp;</Box>
            <Box>{t("company-history-2009-9")}</Box>
            <Box>{t("company-history-2009-10")}</Box>
          </Section>

          <Title>2013 ~ 2016</Title>
          <Section>
            <Box>{t("company-history-2013-1")}</Box>
            <Box>{t("company-history-2013-2")}</Box>
            <Box>{t("company-history-2013-3")}</Box>
            <Box>&nbsp;</Box>
            <Box>{t("company-history-2013-4")}</Box>
            <Box>{t("company-history-2013-5")}</Box>
            <Box>{t("company-history-2013-6")}</Box>
            <Box>&nbsp;</Box>
            <Box>{t("company-history-2013-7")}</Box>
            <Box>{t("company-history-2013-8")}</Box>
            <Box>{t("company-history-2013-9")}</Box>
            <Box>{t("company-history-2013-10")}</Box>
            <Box>&nbsp;</Box>
            <Box>{t("company-history-2013-11")}</Box>
            <Box>{t("company-history-2013-12")}</Box>
            <Box>{t("company-history-2013-13")}</Box>
            <Box>{t("company-history-2013-14")}</Box>
            <Box>{t("company-history-2013-15")}</Box>
            <Box>{t("company-history-2013-16")}</Box>
            <Box>{t("company-history-2013-17")}</Box>
          </Section>
        </Box>
      </ContentsBox>
      <StyledDivider style={{ marginTop: "50px" }} />
      <ContentsBox>
        <h2>{t("company-location")}</h2>
        <iframe
          title="Location of IMPEC on Google Maps"
          src={getLocalizedMapUrl(locale)}
          height={600}
          style={{ border: 0 }}
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />
        <div
          style={{
            textAlign: "end",
          }}
        >
          <Box>{t("address1")}</Box>
          <Box>{t("address2")}</Box>
          <div>
            {t("telephone-prefix")}
            {t("telephone-number")}
          </div>
          <div>
            {t("fax-prefix")}
            {t("fax-number")}
          </div>
        </div>
      </ContentsBox>
      <StyledDivider style={{ marginTop: "20px", marginBottom: "20px" }} />
    </DefaultLayout>
  );
}

export const query = graphql`
  query {
    logo: file(name: { eq: "impec" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100)
      }
    }
  }
`;
